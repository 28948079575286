<template>
  <div class="home" :class="{ 'find-gift': findGift }">
    <div class="title">
      <div>
        <h1>
          {{ home.title }}
          <div class="handwriting">
            {{ home.subtitle }}
          </div>
        </h1>
      </div>
    </div>

    <div v-if="!findGift" class="show-find-gift">
      <!--      <div class="container">-->
      <!--        <p v-for="sentence in home.welcome" :key="sentence">{{ sentence }}</p>-->
      <!--      </div>-->

      <button class="mf-title" @click="transition">
        {{ home.button }}
      </button>
    </div>

    <Finder :show="findGift" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Finder from "@/components/Finder";

export default {
  name: "Home",
  components: {
    Finder,
  },

  data() {
    return {
      findGift: false,
    };
  },

  computed: {
    ...mapGetters(["home"]),
  },

  methods: {
    transition() {
      this.findGift = true;
    },
  },
};
</script>
<style lang="scss">
.home {
  height: 100vh;
  width: 100vw;

  .show-find-gift {
    position: absolute;
    bottom: 5vh;
    width: 100%;
    text-align: center;

    > button {
      background-color: transparent;
      border-radius: 5px;

      width: max-content;
      height: max-content;

      padding: 1rem;

      border: 2px solid $secondary;
      outline: none;
      font-family: $quicksand;

      color: $secondary;
      font-size: 2rem;
      font-weight: bold;

      &:hover {
        background-color: rgba($secondary, 0.1);
        backdrop-filter: blur(5px);
      }
    }
  }

  .title {
    display: grid;
    justify-content: center;

    background-image: url("../assets/images/banner.svg");
    background-position: center -20vh;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    height: 100vh;

    h1 {
      margin: 15vh 0 0;
    }
  }

  &.find-gift {
    .title {
      height: 25%;
      transition: height 1s;
      background-position: center 56%;

      > div {
        width: max-content;
        height: max-content;

        h1 {
          margin-top: 1vh;
          font-size: 3rem;
          transition: all 1s;

          .handwriting {
            transition: all 1s;
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}
</style>
