<template>
  <div>
    Neem alvast een kijkje op
    <a href="https://www.instagram.com/giftsbymaaike/">mijn instagram</a> voor
    inspiratie!
  </div>

  <div class="images__container">
    <div class="images">
      <a
        v-for="product in products.slice(0, 6)"
        :key="product.name"
        class="image__container"
        :href="
          product.instagram
            ? product.instagram
            : 'https://www.instagram.com/giftsbymaaike/'
        "
      >
        <img
          :src="require('@/assets/images/products/' + product.image)"
          :alt="product.description"
        />

        <div class="overlay">
          <div class="heart">
            <font-awesome-icon icon="heart" />
            {{ product.likes }}
          </div>
          <div class="chat">
            <font-awesome-icon icon="comment" />
            {{ product.comments }}
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Social",

  computed: {
    ...mapGetters(["products", "filteredProducts"]),
  },
};
</script>

<style lang="scss" scoped>
.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  max-width: 50rem;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image__container {
    position: relative;
    width: 15rem;
    height: 15rem;

    img {
      width: 15rem;
      height: 15rem;
      object-fit: cover;
    }

    .overlay {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25); /* Black background with opacity */
      z-index: 10;
      top: 0;
      left: 0;
    }

    &:hover {
      .overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        color: white;
        font-size: 16px;
      }
    }
  }
}

.media-query-xs {
  .images {
    gap: 1rem;
    padding: 2rem 0;
    max-width: 100%;

    .image__container {
      width: 5rem;
      height: 5rem;

      img {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}
</style>
