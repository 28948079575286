import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";

import VueMq from "vue3-mq";

import { i18n } from "./utils/i18n";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faHeart, faComment } from "@fortawesome/free-solid-svg-icons";

library.add(faHeart, faComment);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const app = createApp(App);

app.component("FontAwesomeIcon", FontAwesomeIcon);
app.use(store);
app.use(VueMq, {
  breakpoints: {
    // default breakpoints - customise this
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1400,
    xxl: Infinity,
  },
});
app.use(i18n);
app.mount("#app");
