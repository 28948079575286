<template>
  <section class="container">
    <h2>Tot slot, een aantal praktische zaken.</h2>
    <div>
      <div class="sentences">
        <div class="sentence">
          <div class="sentence--part">Ik wil het cadeau graag op</div>
          <div class="sentence--choose">
            <DatePicker
              v-model="selectedDate"
              mode="date"
              class="date-picker"
              color="gray"
              locale="nl"
            >
              <template #default="{ inputEvents }">
                <div class="date-input" v-on="inputEvents">
                  {{ formatDate() }}
                  <span class="multiselect-caret"></span>
                </div>
              </template>
            </DatePicker>
          </div>
          <div class="sentence--choose">
            <Multiselect
              v-model="selectedDelivery"
              :searchable="$mq !== 'xs'"
              class="multiselect-sentence"
              :options="giftOptions.delivery"
            >
              <template #singlelabel="{ value }">
                <template v-if="value.value === 'deliver_receiver'">
                  {{
                    $t("delivery." + value.value, {
                      receiver: $tc(
                        "person." + selectedPerson,
                        genderisation[selectedGender]
                          ? genderisation[selectedGender]
                          : 0
                      ),
                    })
                  }}
                </template>
                <template v-else>
                  {{ $t("delivery." + value.value) }}
                </template>
              </template>
              <template #option="{ option }">
                <template v-if="option.value === 'deliver_receiver'">
                  {{
                    $t("delivery." + option.value, {
                      receiver: $tc(
                        "person." + selectedPerson,
                        genderisation[selectedGender]
                          ? genderisation[selectedGender]
                          : 0
                      ),
                    })
                  }}
                </template>
                <template v-else>
                  {{ $t("delivery." + option.value) }}
                </template>
              </template>
            </Multiselect>
          </div>
        </div>
      </div>
      <div class="sentences">
        <div class="sentence">
          <div class="sentence--part">Ik wil</div>
          <div class="sentence--choose">
            <Multiselect
              v-model="selectedPrice"
              :searchable="$mq !== 'xs'"
              class="multiselect-sentence small"
              :options="giftOptions.price"
            >
              <template #singlelabel="{ value }">
                {{ $t("price.max", { price: value.value }) }}
              </template>
              <template #option="{ option }">
                {{ $t("price.max", { price: option.value }) }}
              </template>
            </Multiselect>
            <div class="sentence--part">uitgeven</div>
            <Multiselect
              v-model="selectedGiftcardValue"
              :searchable="$mq !== 'xs'"
              class="multiselect-sentence small"
              :options="giftOptions.giftcardValues"
            >
              <template #singlelabel="{ value }">
                <template v-if="value.value > 0">{{
                  $tc("giftcard.price", value.value, { amount: value.value })
                }}</template>
                <template v-else>{{ $t("giftcard.noGiftcard") }}</template>
              </template>
              <template #option="{ option }">
                <template v-if="option.value > 0">{{
                  $tc("giftcard.price", option.value, { amount: option.value })
                }}</template>
                <template v-else>{{ $t("giftcard.noGiftcard") }}</template>
              </template>
            </Multiselect>
          </div>
        </div>
      </div>

      <Receipt />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { DatePicker } from "v-calendar";
import Multiselect from "@vueform/multiselect";
import { GenderToIndex } from "@/utils/genderisation";
import Receipt from "@/components/singleComponents/Receipt";

export default {
  name: "When",
  components: {
    Receipt,
    DatePicker,
    Multiselect,
  },
  data() {
    return {
      editing: true,
      dateFormat: {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      },
      genderisation: GenderToIndex,
    };
  },
  computed: {
    ...mapGetters(["home", "giftSelection", "giftOptions"]),

    selectedPerson() {
      return this.giftSelection.person
        ? this.giftSelection.person
        : "acquaintance";
    },

    selectedGender() {
      return this.giftSelection.gender ? this.giftSelection.gender : "else";
    },

    selectedDate: {
      get() {
        return this.giftSelection.date;
      },
      set(val) {
        this.setSelectedDate(val);
        if (this.selectedPrice && this.selectedDate && this.selectedDelivery) {
          this.editing = false;
        }
      },
    },

    selectedDelivery: {
      get() {
        return this.giftSelection.delivery;
      },
      set(val) {
        this.setSelectedDelivery(val);
        this.updateSelectedPrice();
        this.updateSelectedGiftCard();
        this.determineGiftcardValues();
      },
    },

    selectedPrice: {
      get() {
        return this.giftSelection.price;
      },
      set(val) {
        this.setSelectedPrice(val);
        this.updateSelectedGiftCard();
        this.determineGiftcardValues();
      },
    },

    selectedGiftcardValue: {
      get() {
        return this.giftSelection.giftcardValue;
      },
      set(val) {
        this.setSelectedGiftcardValue(val);
        this.updateSelectedPrice();
        this.updateSelectedGiftCard();
      },
    },
  },
  mounted() {
    this.determineGiftcardValues();
  },

  methods: {
    ...mapActions([
      "determineGiftcardValues",
      "updateSelectedPrice",
      "updateSelectedGiftCard",
    ]),
    ...mapMutations([
      "setSelectedDate",
      "setSelectedDelivery",
      "setSelectedPrice",
      "setSelectedGiftcardValue",
    ]),

    formatDate() {
      if (this.selectedDate) {
        const formatDate = new Date(this.selectedDate);
        return formatDate.toLocaleDateString("nl-nl", this.dateFormat);
      }
    },
  },
};
</script>

<style lang="scss">
.date-input {
  width: max-content;
  min-width: 200px;
  height: 40px;

  display: flex;
  align-items: center;
  cursor: pointer;

  background: transparent;
  border-bottom: 1px solid #8ba4b3;
  padding-left: 14px;
  padding-right: 14px;

  position: relative;
}
</style>
