// import axios from "axios";
const actions = {
  sendEmail: async (params) => {
    console.log(params);

    return params;
    // axios
    //   .post("https://api.emailjs.com/api/v1.0/email/send", {
    //     user_id: "user_AUGpzqcIwQEgeqi3qDJDf",
    //     service_id: "service_r570b4n",
    //     template_id: "template_nco06tf",
    //     template_params: {
    //       to_name: "Besteller",
    //       reply_to: "michelle@frankhuizen.nl",
    //       order: order,
    //       to_email: "michelle@frankhuizen.nl",
    //     },
    //   })
    //   .then((response) => console.log(response))
    //   .catch((error) => console.log(error));
  },
};

export default {
  namespaced: true,
  actions,
};
