<template>
  <div class="footer">
    <div class="socials">
      <a target="_blank" href="">
        <svg
          id="Layer_1"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="45px"
          height="45px"
          viewBox="0 0 56.693 56.693"
          enable-background="new 0 0 56.693 56.693"
          xml:space="preserve"
        >
          <path
            d="M28.347,5.157c-13.6,0-24.625,11.027-24.625,24.625c0,13.6,11.025,24.623,24.625,24.623c13.6,0,24.625-11.023,24.625-24.623
	C52.972,16.184,41.946,5.157,28.347,5.157z M34.864,29.679h-4.264c0,6.814,0,15.207,0,15.207h-6.32c0,0,0-8.307,0-15.207h-3.006
	V24.31h3.006v-3.479c0-2.49,1.182-6.377,6.379-6.377l4.68,0.018v5.215c0,0-2.846,0-3.398,0c-0.555,0-1.34,0.277-1.34,1.461v3.163
	h4.818L34.864,29.679z"
          />
        </svg>
      </a>
      <a target="_blank" href="https://www.instagram.com/giftsbymaaike/">
        <svg
          id="Layer_1"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="45px"
          height="45px"
          viewBox="0 0 56.7 56.7"
          style="enable-background: new 0 0 56.7 56.7"
          xml:space="preserve"
        >
          <g>
            <circle cx="28.1" cy="30" r="4.4" />
            <path
              d="M33.6,19.2h-11c-1.6,0-3,0.5-3.9,1.4c-0.9,0.9-1.4,2.3-1.4,3.9v11c0,1.6,0.5,3,1.5,4c1,0.9,2.3,1.4,3.9,1.4h10.9
		c1.6,0,3-0.5,3.9-1.4c1-0.9,1.5-2.3,1.5-3.9v-11c0-1.6-0.5-2.9-1.4-3.9C36.6,19.7,35.3,19.2,33.6,19.2z M28.1,36.8
		c-3.8,0-6.8-3.1-6.8-6.8c0-3.8,3.1-6.8,6.8-6.8S35,26.2,35,30C35,33.8,31.9,36.8,28.1,36.8z M35.2,24.5c-0.9,0-1.6-0.7-1.6-1.6
		s0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6S36.1,24.5,35.2,24.5z"
            />
            <path
              d="M28.3,5.2c-13.6,0-24.6,11-24.6,24.6c0,13.6,11,24.6,24.6,24.6c13.6,0,24.6-11,24.6-24.6C53,16.2,41.9,5.2,28.3,5.2z
		 M41.4,35.6c0,2.3-0.8,4.3-2.2,5.7c-1.4,1.4-3.4,2.1-5.6,2.1H22.7c-2.2,0-4.2-0.7-5.6-2.1c-1.5-1.4-2.2-3.4-2.2-5.7v-11
		c0-4.6,3.1-7.8,7.8-7.8h11c2.3,0,4.2,0.8,5.6,2.2c1.4,1.4,2.1,3.3,2.1,5.6V35.6z"
            />
          </g>
        </svg>
      </a>
      <a target="_blank" href="https://pinterest.com/dejager2298">
        <svg
          id="Layer_1"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="45px"
          height="45px"
          viewBox="0 0 56.693 56.693"
          enable-background="new 0 0 56.693 56.693"
          xml:space="preserve"
        >
          <g>
            <path
              d="M28.348,5.158c-13.599,0-24.625,11.023-24.625,24.625c0,10.082,6.063,18.744,14.739,22.553
		c-0.069-1.721-0.012-3.783,0.429-5.654c0.473-2,3.168-13.418,3.168-13.418s-0.787-1.572-0.787-3.896
		c0-3.648,2.115-6.373,4.749-6.373c2.24,0,3.322,1.682,3.322,3.695c0,2.252-1.437,5.619-2.175,8.738
		c-0.616,2.613,1.31,4.744,3.887,4.744c4.665,0,7.808-5.992,7.808-13.092c0-5.397-3.635-9.437-10.246-9.437
		c-7.47,0-12.123,5.57-12.123,11.792c0,2.146,0.633,3.658,1.624,4.83c0.455,0.537,0.519,0.754,0.354,1.371
		c-0.118,0.453-0.389,1.545-0.501,1.977c-0.164,0.625-0.669,0.848-1.233,0.617c-3.44-1.404-5.043-5.172-5.043-9.408
		c0-6.994,5.899-15.382,17.599-15.382c9.4,0,15.588,6.804,15.588,14.107c0,9.658-5.369,16.875-13.285,16.875
		c-2.659,0-5.16-1.438-6.016-3.068c0,0-1.43,5.674-1.732,6.768c-0.522,1.9-1.545,3.797-2.479,5.275
		c2.215,0.654,4.554,1.01,6.979,1.01c13.598,0,24.623-11.023,24.623-24.623C52.971,16.181,41.945,5.158,28.348,5.158z"
            />
          </g>
        </svg>
      </a>
    </div>
    <div class="links">
      <router-link class="quicksand" to="info">Info</router-link>
      <a class="quicksand" href="#">???</a>
      <a class="quicksand" href="#">Contact</a>
    </div>
    <div class="copyright">
      <p>&copy; 2021 Personal gifts by Maaike</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Footer",

  props: {},

  data() {
    return {};
  },

  computed: {
    ...mapGetters("", []),
  },

  methods: {
    ...mapActions("", []),

    navigate(location) {
      console.log(location);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  grid-gap: 0.6rem;

  justify-items: center;
  align-content: center;

  div.socials {
    display: grid;
    grid-template-columns: repeat(3, 45px);
    grid-template-rows: 45px;
    grid-gap: 0.3rem;
    margin: 0.6rem;

    > a {
      svg {
        fill: $white;
      }

      &:hover {
        svg {
          fill: $grey;
        }
      }
    }
  }

  div.links {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-template-rows: max-content;
    grid-gap: 1rem;

    a {
      color: white;
      text-decoration: none;
      font-size: 14px;

      &:hover {
        color: $grey;
      }
    }
  }

  div.copyright {
    color: $grey;

    p {
      font-size: 12px;
      margin: 0;
    }
  }
}
</style>
