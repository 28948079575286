<template>
  <component :is="currentView" :class="'media-query-' + $mq" />

  <div v-if="!isProduction" class="mq">{{ $mq }}</div>
</template>

<script>
import ComingSoon from "./views/ComingSoon.vue";
import Home from "./views/Home.vue";
import Info from "./views/Info.vue";

const routes = {
  "/": ComingSoon,
  "/preview": Home,
  "/about": Info,
};

export default {
  data() {
    return {
      isProduction: process.env.NODE_ENV === "production",
      currentPath: window.location.hash,
    };
  },
  computed: {
    currentView() {
      return routes[this.currentPath.slice(1) || "/"] || ComingSoon;
    },
  },
  mounted() {
    window.addEventListener("hashchange", () => {
      this.currentPath = window.location.hash;
    });
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
body,
html {
  margin: 0;
  padding: 0;
  background-color: $background;

  #app {
    height: 100vh;
    width: 100vw;
    overflow: auto;

    color: $font;
    font-family: $quicksand !important;
    font-size: 14px !important;

    .multiselect > * {
      font-size: 14px !important;
    }
  }

  .mq {
    position: absolute;
    right: 0;
    bottom: 0;

    padding: 5px;
    background-color: $white;
  }
}
</style>
