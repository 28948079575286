<template>
  <div class="coming-soon">
    <div class="title">
      <div>
        <h1>
          {{ home.title }}
          <div class="handwriting">
            {{ home.subtitle }}
          </div>
        </h1>
      </div>
    </div>
    <div class="content">
      <h2>Website komt eraan!</h2>
      <p>
        We werken nog hard aan de website! <br />
        Dit betekent niet dat ik niet nu al een mooie personal gift voor jou kan
        maken.
      </p>

      <Social />

      <p>Interesse of meer informatie nodig? Stuur mij dan een mailtje!</p>

      <a class="handwriting" href="mailto:info@giftsbymaaike.nl"
        >info@giftsbymaaike.nl</a
      >

      <p>Groetjes,</p>

      <p class="handwriting">Maaike</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Social from "@/components/Instagram";

export default {
  name: "ComingSoon",
  components: { Social },
  computed: {
    ...mapGetters(["home"]),
  },
};
</script>
<style lang="scss">
.coming-soon {
  height: 100vh;
  width: 100vw;

  .content {
    width: 100%;
    text-align: center;
    overflow: auto;

    p.handwriting {
      font-size: 2rem;
    }
  }

  .title {
    display: grid;
    justify-content: center;

    background-image: url("../assets/images/banner.svg");
    background-position: center 56%;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    height: 25%;

    h1 {
      margin-top: 1vh;
      font-size: 3rem;
      transition: all 1s;

      .handwriting {
        transition: all 1s;
        font-size: 1.8rem;
      }
    }
  }
}

.media-query-xs {
  &.coming-soon {
    .content {
      width: unset;
      padding: 0 1rem;
    }
  }
}
</style>
