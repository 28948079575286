<template>
  <section class="container">
    <div v-if="editing || $mq === 'xs'">
      <h2>Voor wie zoek je een cadeau?</h2>

      <div class="sentences">
        <div class="sentence">
          <div class="sentence--part">Ik zoek een cadeau voor mijn</div>
          <div class="sentence--choose__double">
            <div class="sentence--choose">
              <Multiselect
                v-model="selectedGender"
                :searchable="$mq !== 'xs'"
                class="multiselect-sentence"
                :options="giftOptions.gender"
              >
                <template #singlelabel="{ value }">
                  {{ $t("gender." + value.value) }}
                </template>
                <template #option="{ option }">
                  {{ $t("gender." + option.value) }}
                </template>
              </Multiselect>
            </div>

            <div class="sentence--choose">
              <Multiselect
                v-model="selectedPerson"
                :searchable="$mq !== 'xs'"
                class="multiselect-sentence"
                :options="giftOptions.person"
              >
                <template #singlelabel="{ value }">
                  {{
                    $tc(
                      "person." + value.value,
                      genderisation[selectedGender]
                        ? genderisation[selectedGender]
                        : 0
                    )
                  }}
                </template>
                <template #option="{ option }">
                  {{
                    $tc(
                      "person." + option.value,
                      genderisation[selectedGender]
                        ? genderisation[selectedGender]
                        : 0
                    )
                  }}
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
        <div class="sentence">
          <div class="sentence--part">
            Mijn {{ selectedPerson ? selectedPerson : "persoon" }}
          </div>
          <div class="sentence--choose__triple">
            <div class="sentence--choose">
              <Multiselect
                v-model="selectedOccasion"
                class="multiselect-sentence"
                :options="giftOptions.occasion"
              >
                <template #singlelabel="{ value }">
                  {{ $t("occasion." + value.value, 0) }}
                </template>
                <template #option="{ option }">
                  {{ $t("occasion." + option.value, 0) }}
                </template>
              </Multiselect>
            </div>
            <div class="sentence--part">
              {{
                selectedOccasion && selectedOccasion === "is jarig"
                  ? "en wordt"
                  : "en is"
              }}
            </div>
            <div class="sentence--choose">
              <Multiselect
                v-model="selectedAge"
                :searchable="$mq !== 'xs'"
                class="multiselect-sentence small"
                :options="giftOptions.age"
              >
                <template #singlelabel="{ value }">
                  <template
                    v-if="!selectedOccasion || selectedOccasion !== 'birthday'"
                  >
                    {{ $t("age." + value.value) }}
                  </template>
                  <template v-else>
                    {{ $t("age.any", { n: value.value }) }}
                  </template>
                </template>
                <template #option="{ option }">
                  <template
                    v-if="!selectedOccasion || selectedOccasion !== 'birthday'"
                  >
                    {{ $t("age." + option.value) }}
                  </template>
                  <template v-else>
                    {{ $t("age.any", { n: option.value }) }}
                  </template>
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else :class="{ editing: editing }" @click="editing = !editing">
      <h2>Voor wie zoek je een cadeau?</h2>

      <div class="sentences">
        <div class="sentence">
          Ik zoek een cadeau voor mijn {{ selectedGender }}
          {{ selectedPerson ? selectedPerson : "persoon" }}.
        </div>
        <div class="sentence">
          Mijn {{ selectedPerson ? selectedPerson : "persoon" }}
          {{ selectedOccasion }}
          {{
            selectedOccasion && selectedOccasion === "is jarig"
              ? "en wordt"
              : "en is"
          }}
          {{ selectedAge }}.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { GenderToIndex } from "@/utils/genderisation";

export default {
  name: "WhatPerson",
  components: {
    Multiselect,
  },
  data() {
    return {
      editing: true,
      genderisation: GenderToIndex,
    };
  },
  computed: {
    ...mapGetters(["giftSelection", "giftOptions"]),

    selectedGender: {
      get() {
        return this.giftSelection.gender;
      },
      set(val) {
        this.setSelectedGender(val);
        this.filterProducts();
        if (
          this.selectedGender &&
          this.selectedPerson &&
          this.selectedAge &&
          this.selectedOccasion
        ) {
          this.editing = false;
        }
      },
    },

    selectedPerson: {
      get() {
        return this.giftSelection.person;
      },
      set(val) {
        this.setSelectedPerson(val);
        this.filterProducts();
        if (
          this.selectedGender &&
          this.selectedPerson &&
          this.selectedAge &&
          this.selectedOccasion
        ) {
          this.editing = false;
        }
      },
    },

    selectedAge: {
      get() {
        return this.giftSelection.age;
      },
      set(val) {
        this.setSelectedAge(val);
        this.filterProducts();
        if (
          this.selectedGender &&
          this.selectedPerson &&
          this.selectedAge &&
          this.selectedOccasion
        ) {
          this.editing = false;
        }
      },
    },

    selectedOccasion: {
      get() {
        return this.giftSelection.occasion;
      },
      set(val) {
        this.setSelectedOccasion(val);
        this.determineAge();
        this.filterProducts();
        if (
          this.selectedGender &&
          this.selectedPerson &&
          this.selectedAge &&
          this.selectedOccasion
        ) {
          this.editing = false;
        }
      },
    },
  },
  mounted() {
    this.determineAge();
  },
  methods: {
    ...mapActions(["determineAge", "filterProducts"]),
    ...mapMutations([
      "setSelectedGender",
      "setSelectedPerson",
      "setSelectedAge",
      "setSelectedOccasion",
    ]),
  },
};
</script>
