<template>
  <section class="container">
    <h2>Wat voor stijl spreekt je aan?</h2>

    <div v-if="filteredProducts && filteredProducts.length" class="products">
      <Product
        v-for="product in filteredProducts.slice(0, 5)"
        :key="product.name"
        :url="product.url"
      />
    </div>

    <div v-else class="products">
      <Product
        v-for="product in products.slice(0, 5)"
        :key="product.name"
        :url="product.url"
      />
    </div>
  </section>
</template>

<script>
import Product from "@/components/singleComponents/Product";
import { mapGetters } from "vuex";

export default {
  name: "WhatDesign",
  components: { Product },
  computed: {
    ...mapGetters(["products", "filteredProducts"]),
  },
};
</script>

<style lang="scss">
.container {
  text-align: center;
}
.products {
  display: grid;
  grid-template-columns: repeat(5, 172px);
  grid-auto-rows: 172px;
}

.media-query-xs {
  .products {
    grid-template-columns: calc(100vw / 2) calc(100vw / 2);
    grid-auto-rows: calc(100vw / 2);
    margin: 0 1rem;
  }
}
</style>
