export default {
  gender: {
    female: "female",
    male: "male",
    neutral: "gender neutral",
    else: "else",
    na: "N/A",
  },
  person: {
    friend: "friend",
    child: "child",
    partner: "partner",
    sibling: "brother/sister",
    parent: "father/mother",
    grandparent: "grandpa/grandma",
    auncle: "uncle/aunt",
    colleague: "colleague",
    acquaintance: "acquaintance",
    else: "else",
    na: "N/A",
  },
  occasion: {
    birthday: "is jarig | zijn jarig",
    wedding: "gaat trouwen | gaan trouwen",
    child: "krijgt een kind | krijgen een kind",
    retirement: "gaat met pensioen | gaan met pensioen",
    anniversary: "bereikt een jubileum | bereiken een jubileum",
    graduation: "is geslaagd | zijn geslaagd",
    home: "heeft een nieuwe woning | hebben een nieuwe woning",
    thanks: "wil ik graag bedanken",
    support: "wil ik graag steunen",
    comfort: "wil ik graag troosten",
    else: "else",
    na: "N/A",
  },
  price: {
    max: "maximaal €{price}",
  },
  giftcard: {
    with: "met cadeaubon",
    without: "zonder cadeaubon",
  },
};
