<template>
  <div v-if="product" class="product" @click="selected = !selected">
    <img
      :src="require('@/assets/images/products/' + product.image)"
      :alt="product.description"
    />

    <div class="info">{{ product.title }}</div>

    <div class="selected" :class="{ check: selected }">
      <svg
        v-if="selected"
        class="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path
          class="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Product",
  props: {
    url: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isMounted: false,
    };
  },

  computed: {
    ...mapGetters(["getProduct", "isProductSelected"]),
    product() {
      return this.getProduct(this.url);
    },
    selected: {
      get() {
        return this.isProductSelected(this.url);
      },
      set(val) {
        this.selectProduct({ url: this.url, selected: val });
      },
    },
  },

  methods: {
    ...mapActions(["selectProduct"]),
  },
};
</script>

<style lang="scss">
.product {
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .info {
    display: none;
    transition: all 2s;
  }

  &:hover .info {
    position: absolute;
    transition: all 2s;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 50px;
    height: 20%;

    display: grid;
    justify-content: center;
    align-content: center;

    background-color: rgba($primary, 0.6);
    backdrop-filter: blur(8px);

    color: $white;
  }

  .selected {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px;

    &.check {
      .checkmark__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: $primary;
        fill: none;
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
      }

      .checkmark {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #fff;
        stroke-miterlimit: 10;
        margin: 10% auto;
        box-shadow: inset 0px 0px 0px $primary;
        animation: fill 0.4s ease-in-out 0.4s forwards,
          scale 0.3s ease-in-out 0.9s both;
      }

      .checkmark__check {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
      }
    }

    @keyframes stroke {
      100% {
        stroke-dashoffset: 0;
      }
    }

    @keyframes scale {
      0%,
      100% {
        transform: none;
      }
      50% {
        transform: scale3d(1.1, 1.1, 1);
      }
    }

    @keyframes fill {
      100% {
        box-shadow: inset 0 0 0 30px $primary;
      }
    }
  }
}
</style>
