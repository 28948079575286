<template>
  <div v-if="show" class="content">
    <WhatPerson />

    <WhatDesign />

    <When />

    <Send />

    <Footer />
  </div>
</template>

<script>
import WhatPerson from "@/components/WhatPerson";
import WhatDesign from "@/components/WhatDesign";
import When from "@/components/When";
import Send from "@/components/Send";
import Footer from "@/components/Footer";

export default {
  name: "Finder",
  components: {
    Footer,
    Send,
    WhatPerson,
    WhatDesign,
    When,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.content {
  height: 75%;
  overflow: auto;
}
</style>
