<template>
  <div class="receipt-container">
    <div class="receipt">
      <h1 class="logo">
        {{ home.title }}
        <div class="handwriting">
          {{ home.subtitle }}
        </div>
      </h1>
      <div class="items">
        <div v-if="computedGiftPrice" class="item">Personal gift</div>
        <div v-if="computedGiftPrice" class="price">
          {{
            computedGiftPrice.toLocaleString("nl-NL", {
              style: "currency",
              currency: "EUR",
            })
          }}
        </div>
        <div v-if="selectedGiftcardValue" class="item">Giftcard</div>
        <div v-if="selectedGiftcardValue" class="price">
          {{
            selectedGiftcardValue.toLocaleString("nl-NL", {
              style: "currency",
              currency: "EUR",
            })
          }}
        </div>
        <div v-if="selectedDelivery" class="item">Verzendkosten</div>
        <div v-if="selectedDelivery" class="price">
          {{
            (selectedDelivery !== "fetch" ? 5.0 : 0.0).toLocaleString("nl-NL", {
              style: "currency",
              currency: "EUR",
            })
          }}
        </div>
        <div v-if="selectedPrice" class="total item">Totaal</div>
        <div v-if="selectedPrice" class="total price">
          {{
            selectedPrice.toLocaleString("nl-NL", {
              style: "currency",
              currency: "EUR",
            })
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Receipt",

  computed: {
    ...mapGetters(["home", "giftSelection"]),

    computedGiftPrice() {
      let price = 0.0;
      if (this.selectedPrice) {
        price = parseFloat(this.selectedPrice);
      }

      if (this.selectedDelivery && this.selectedDelivery !== "fetch") {
        price -= 5;
      }

      if (this.selectedGiftcardValue) {
        price -= parseFloat(this.selectedGiftcardValue);
      }

      if (price < 0) {
        return 0.0;
      }
      return price;
    },

    selectedDelivery: {
      get() {
        return this.giftSelection.delivery;
      },
      set(val) {
        this.setSelectedDelivery(val);
        this.updateSelectedGiftCard();
        this.determineGiftcardValues();
      },
    },

    selectedPrice: {
      get() {
        return this.giftSelection.price;
      },
      set(val) {
        this.setSelectedPrice(val);
        this.updateSelectedGiftCard();
        this.determineGiftcardValues();
      },
    },

    selectedGiftcardValue: {
      get() {
        return this.giftSelection.giftcardValue;
      },
      set(val) {
        this.setSelectedGiftcardValue(val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.receipt-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1rem;

  .receipt {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem;
    background-color: white;
    width: 200px;
    min-height: 280px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px;

    h1.logo {
      font-size: 2rem;
      color: $primary;
      width: 200px;

      .handwriting {
        font-size: 1.2rem;
      }
    }

    > .items {
      display: grid;
      grid-template-columns: auto auto;
      grid-auto-rows: max-content;

      grid-row-gap: 0.5rem;

      .item {
        text-align: left;
      }

      .price {
        text-align: right;
      }

      .total {
        font-weight: bold;
        font-size: 1.2rem;
        border-top: 1px dashed $primary;
        padding-top: 0.5rem;
      }
    }
  }
}
</style>
