import { createI18n } from "vue-i18n";
import Dutch from "@/assets/translations/nl-nl";
import EnglishGB from "@/assets/translations/en-gb";

export const i18n = createI18n({
  locale: "nl-nl",
  fallbackLocale: "nl-nl",
  globalInjection: true,
  messages: {
    "nl-nl": Dutch,
    "en-gb": EnglishGB,
  },
});
