export default {
  gender: {
    female: "vrouwelijke",
    male: "mannelijke",
    neutral: "genderneutrale",
    else: "anders",
    na: "n.v.t.",
  },
  person: {
    friend: "vriend/vriendin | vriend | vriendin",
    child: "kind | zoon | dochter",
    partner: "partner | partner | partner",
    sibling: "broer/zus | broer | zus",
    parent: "vader/moeder | vader | moeder",
    grandparent: "opa/oma | opa | oma",
    auncle: "oom/tante | oom | tante",
    colleague: "collega | collega | collega",
    acquaintance: "kennis | kennis | kennis",
    else: "anders | anders | anders",
    na: "n.v.t. | n.v.t. | n.v.t.",
  },
  age: {
    10: "een tiener",
    20: "in de twintig",
    30: "in de dertig",
    40: "in de veertig",
    50: "in de vijftig",
    60: "in de zestig",
    70: "in de zeventig",
    80: "in de tachtig",
    90: "in de negentig",
    100: "in de 100",
    110: "in de 110",
    120: "in de 120",
    else: "anders",
    na: "n.v.t.",
    any: "{n} jaar",
  },
  occasion: {
    birthday: "is jarig | zijn jarig",
    wedding: "gaat trouwen | gaan trouwen",
    child: "krijgt een kind | krijgen een kind",
    retirement: "gaat met pensioen | gaan met pensioen",
    anniversary: "bereikt een jubileum | bereiken een jubileum",
    graduation: "is geslaagd | zijn geslaagd",
    home: "heeft een nieuwe woning | hebben een nieuwe woning",
    thanks: "wil ik graag bedanken",
    support: "wil ik graag steunen",
    comfort: "wil ik graag troosten",
    else: "anders",
    na: "n.v.t.",
  },
  delivery: {
    fetch: "zelf ophalen",
    deliver_sender: "laten bezorgen bij mij (€5,-)",
    deliver_receiver: "laten bezorgen bij mijn {receiver} (€5,-)",
  },
  price: {
    max: "maximaal €{price}",
  },
  giftcard: {
    noGiftcard: "zonder cadeaubon",
    price: "met cadeaubon t.w.v. €{amount}",
  },
};
