<template>
  <section class="container">
    <!--    <h2>Wat voor stijl spreekt je aan?</h2>-->

    <button class="mf-title" @click="sendEmail">Let's go!</button>
  </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Send",
  components: {},
  computed: {},
  methods: {
    ...mapActions("email", ["sendEmail"]),
  },
};
</script>

<style scoped lang="scss">
.container {
  text-align: center;
}
.products {
  display: grid;
  grid-template-columns: repeat(5, 172px);
}

.media-query-xs {
  .products {
    grid-template-columns: 1fr 1fr;
    margin: 0 1rem;
  }
}
</style>
