<template>
  <div class="info">
    <div class="title">
      <div>
        <h1>
          {{ home.title }}
          <div class="handwriting">
            {{ home.subtitle }}
          </div>
        </h1>
      </div>
    </div>
    <div class="content container">
      <div class="design__container">
        <div
          v-if="!isXS"
          class="circle large striped"
          style="top: 30%; right: 0"
        />
        <div class="circle img" style="top: 10%; left: 26%; z-index: 1">
          <img :src="require('@/assets/images/about/holiday.jpeg')" alt="" />
        </div>
        <div v-if="!isXS" class="circle small" style="top: 28%; left: 10%" />
        <div v-if="!isXS" class="circle large" style="top: 8%; left: 45%" />

        <div
          :class="{ 'square white': !isXS }"
          :style="!isXS ? 'top: 40%; left: 25%; width: 60%' : ''"
        >
          <h1 v-if="!isXS">About</h1>

          <div class="text">
            <p>
              Zoek je een cadeau, maar weet je niet precies wat je moet geven?
              Ik kan je helpen. Aller eerst, welkom en wat leuk dat je mijn
              website bezoekt.
            </p>
            <p>
              Je bent dus opzoek naar een cadeau, maar je weet niet precies wat
              je wilt geven. Het is en blijft ook lastig om een cadeau voor
              iemand uit te zoeken. Geld of een cadeaubon is vaak een
              gemakkelijke uitweg en meestal ook het cadeau waar je op uitkomt.
            </p>
            <p>
              Persoonlijk vind ik het een saai cadeau om te geven, maar het is
              vaak wel het cadeau waar je iemand het grootste plezier mee doet.
            </p>
            <p>
              Mijn bedoeling is om dit saaie cadeau niet meer saai en juist
              spectaculair te maken.
            </p>

            <p>Groetjes,</p>

            <p class="handwriting">Maaike</p>
          </div>
        </div>
        <div v-if="!isXS" class="square" style="top: 10%; left: 10%">
          <h3>PERSONAL</h3>
        </div>
        <div v-if="!isXS" class="square" style="top: 0%; left: 80%">
          <h3>BASKET</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Info",

  computed: {
    ...mapGetters(["home"]),

    isXS() {
      return this.$mq === "xs";
    },
  },
};
</script>
<style lang="scss">
.info {
  width: 100vw;

  .content {
    text-align: center;
    overflow: auto;

    img {
      height: 400px;
      width: 100%;
      object-fit: cover;
    }

    .text {
      max-width: 600px;
      margin: auto;
    }

    p {
      .handwriting {
        font-size: 2rem;
      }
    }
  }

  .title {
    display: grid;
    justify-content: center;

    background-image: url("../assets/images/banner.svg");
    background-position: center 56%;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    height: 25%;

    h1 {
      margin-top: 1vh;
      font-size: 3rem;
      transition: all 1s;

      .handwriting {
        transition: all 1s;
        font-size: 1.8rem;
      }
    }
  }

  .design__container {
    position: relative;
    display: block;
    height: 650px;

    overflow: hidden;

    .circle {
      position: absolute;
      width: 200px;
      height: 200px;
      background-color: rgba($tertiary, 0.2);

      border-radius: 50%;
      padding: 1rem;

      &.small {
        width: 150px;
        height: 150px;
      }

      &.large {
        width: 280px;
        height: 280px;
      }

      &.striped {
        background-image: linear-gradient(
          45deg,
          #000000 25%,
          $background 25%,
          $background 50%,
          #000000 50%,
          #000000 75%,
          $background 75%,
          $background 100%
        );
        background-size: 40px 40px;
      }

      &.img {
        background-color: rgba($tertiary, 0.4);
      }

      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }

    .square {
      position: absolute;
      width: max-content;
      border: 10px solid rgba($tertiary, 0.3);

      padding: 1rem;

      h1 {
        color: black;
        text-align: left;
        margin: 0;
      }

      h3 {
        font-weight: normal;
        color: black;
        margin: 0;
      }

      &.small {
        width: max-content;
      }

      &.large {
        width: 500px;
      }

      &.white {
        background-color: white;
        color: black;
        border: 0;
        padding: 2rem;

        .text {
          column-count: 2;
          font-size: 12px;
          text-align: justify;

          column-gap: 2rem;
          column-rule: 1px solid black;

          p:first-child {
            margin-top: 0;
          }

          p.handwriting {
            font-size: 20px;
            margin: 1rem 0;
          }
        }
      }

      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.media-query-xs {
  &.info {
    .content {
      width: unset;
    }

    .circle {
      position: static;
      width: 300px;
      height: 300px;
      margin: auto;
    }

    .text {
      column-count: 1 !important;
      color: $font;
      padding: 1rem;

      .handwriting {
        font-size: 2rem;
      }
    }
  }

  .design__container {
    display: block;
    position: static;
    height: max-content;
    overflow: auto;
  }
}
</style>
