import { createStore } from "vuex";
import email from "@/store/email";

const DELIVERY_COST = 5;
const MINIMUM_GIFT_PRICE = 10;
const MINIMUM_GIFT_CARD = 5;

export default createStore({
  state: {
    home: {
      title: "personal gifts",
      subtitle: "by Maaike",
      welcome: [
        "Zoek je een cadeau, maar weet je niet precies wat je moet geven? Ik kan je helpen!",
        "Je bent op zoek naar een cadeau, maar je weet niet precies wat je wilt geven. Het is en blijft lastig om een cadeau voor iemand uit te zoeken. Geld of een cadeaubon is vaak een makkelijke uitweg en vaak het cadeau waar je op uitkomt. Persoonlijk vind ik het een saai cadeau om te geven, maar het is wel een cadeau waar je iemand het grootste plezier mee doet.",
        "Mijn bedoeling is om dit saaie cadeau niet meer saai maar juist spectaculair te maken.",
      ],
      button: "Vind mijn personal gift!",
    },
    giftFinder: {
      selected: {
        gender: null,
        person: null,
        age: null,
        occasion: null,
        date: null,
        delivery: null,
        price: null,
        giftcardValue: null,
      },
      options: {
        gender: ["female", "male", "neutral", "else", "na"],
        person: [
          "friend",
          "child",
          "partner",
          "sibling",
          "parent",
          "grandparent",
          "auncle",
          "colleague",
          "acquaintance",
          "else",
          "na",
        ],
        age: [],
        occasion: [
          "birthday",
          "wedding",
          "child",
          "retirement",
          "anniversary",
          "graduation",
          "home",
          "thanks",
          "support",
          "comfort",
          "else",
          "na",
        ],
        date: null,
        delivery: ["fetch", "deliver_sender", "deliver_receiver"],
        price: [
          15, 20, 30, 40, 50, 60, 70, 80, 90, 100, 150, 200, 250, 300, 400, 500,
          600, 800, 1000,
        ],
        giftcardValues: [],
      },
    },
    products: [
      {
        url: "birthday",
        title: "Birthday Celine",
        image: "birthday.jpg",
        description:
          "Dit cadeau heb ik samengesteld voor de 20e verjaardag van Celine.",
        props: {
          gender: ["neutral", "female"],
          occasion: ["birthday", "graduation", "anniversary"],
          person: [
            "friend",
            "child",
            "sibling",
            "colleague",
            "acquaintance",
            "home",
            "comfort",
          ],
        },
        likes: 14,
        comments: 1,
        instagram: "https://www.instagram.com/p/CU4V9IMou5M/",
      },
      {
        url: "birthday_bike_holiday",
        title: "Fietsvakantie cadeau",
        image: "birthday_bike_holiday.jpg",
        description:
          "Fietsvakantie als cadeau geven is laatig. We hebben dit gerealiseerd door een coupon te geven verpakt in een leuke mand vol kleine cadeau's.",
        props: {
          gender: ["neutral", "male", "female"],
          occasion: ["birthday", "graduation", "anniversary", "retirement"],
          person: [
            "friend",
            "child",
            "partner",
            "sibling",
            "parent",
            "grandparent",
            "auncle",
            "colleague",
            "acquaintance",
          ],
        },
        likes: 7,
        comments: 0,
        instagram: "https://www.instagram.com/p/CUpLCf5IFbY/",
      },
      {
        url: "italy",
        title: "Italie",
        image: "italy.jpg",
        description:
          "Een cadeau voor een fan van Italie. Zelfs nonna was jaloers",
        props: {
          gender: ["neutral", "male", "female"],
          occasion: ["birthday", "graduation", "anniversary", "retirement"],
          person: [
            "friend",
            "child",
            "partner",
            "sibling",
            "parent",
            "grandparent",
            "auncle",
            "colleague",
            "acquaintance",
          ],
        },
        likes: 11,
        comments: 1,
        instagram: "https://www.instagram.com/p/CUu6Jfuoj8n/",
      },
      {
        url: "love-red",
        title: "Small gift",
        image: "love-red.jpg",
        description: "Liefde",
        props: {
          gender: ["neutral", "female"],
          occasion: ["birthday", "anniversary"],
          person: ["friend", "partner", "acquaintance"],
        },
        likes: 7,
        comments: 0,
        instagram: "https://www.instagram.com/p/CUy-0uUo6uN/",
      },
      {
        url: "mr-mw",
        title: "MR MW",
        image: "mr-mw.jpg",
        description: "Past bij iedereen",
        props: {
          gender: ["neutral", "male"],
          occasion: [
            "birthday",
            "wedding",
            "child",
            "retirement",
            "anniversary",
            "graduation",
            "home",
            "thanks",
            "support",
            "comfort",
          ],
          person: [
            "friend",
            "child",
            "partner",
            "sibling",
            "parent",
            "grandparent",
            "auncle",
            "colleague",
            "acquaintance",
            "else",
            "na",
          ],
        },
        likes: 7,
        comments: 1,
        instagram: "https://www.instagram.com/p/CUpLOVwoy1B/",
      },
      {
        url: "romance-love",
        title: "Love is in the air",
        image: "romance-love.jpg",
        description: "Liefde",
        props: {
          gender: ["neutral", "female"],
          occasion: [
            "birthday",
            "wedding",
            "retirement",
            "anniversary",
            "graduation",
            "home",
            "thanks",
            "support",
            "comfort",
          ],
          person: [
            "friend",
            "partner",
            "parent",
            "grandparent",
            "acquaintance",
          ],
        },
        likes: 6,
        comments: 0,
        instagram: "https://www.instagram.com/p/CUpK05poh3I/",
      },
      {
        url: "time-to-relax",
        title: "Time to Relax",
        image: "time-to-relax.jpg",
        description: "Time to Relax",
        props: {
          gender: ["neutral", "female"],
          occasion: [
            "birthday",
            "wedding",
            "retirement",
            "anniversary",
            "graduation",
            "home",
            "thanks",
            "support",
            "comfort",
          ],
          person: [
            "friend",
            "partner",
            "parent",
            "grandparent",
            "acquaintance",
          ],
        },
        likes: 8,
        comments: 0,
        instagram: "https://www.instagram.com/p/CUpLWwZIAAp/",
      },
    ],
    filteredProducts: [],
    selectedProducts: [],
  },
  getters: {
    home: (state) => state.home,
    giftSelection: (state) => state.giftFinder.selected,
    giftOptions: (state) => state.giftFinder.options,
    products: (state) => state.products,
    getProduct: (state) => (url) => {
      return state.products.find((product) => product.url === url);
    },
    filteredProducts: (state) => state.filteredProducts,
    selectedProducts: (state) => state.selectedProducts,
    isProductSelected: (state) => (url) => {
      return state.selectedProducts.includes(url);
    },
  },
  actions: {
    determineGiftcardValues({ getters, commit }) {
      let values = [0];

      if (getters.giftSelection.price) {
        let price = parseInt(getters.giftSelection.price) - MINIMUM_GIFT_PRICE;

        if (
          getters.giftSelection.delivery &&
          getters.giftSelection.delivery !== "fetch"
        ) {
          price -= DELIVERY_COST;
        }

        if (price >= MINIMUM_GIFT_CARD) {
          price += 1;
          values = [...Array(price).keys()].filter(
            (value) =>
              (value <= 25 && value % 5 === 0) ||
              (value > 25 && value % 10 === 0)
          );
        }
      }

      commit("setGiftcardValuesOptions", values);
    },

    updateSelectedPrice({ getters, commit }) {
      if (
        getters.giftSelection.price &&
        getters.giftSelection.price < MINIMUM_GIFT_PRICE + DELIVERY_COST &&
        getters.giftSelection.delivery !== "fetch"
      ) {
        commit("setSelectedPrice", null);
      }
    },

    updateSelectedGiftCard({ getters, commit }) {
      let price = getters.giftSelection.price;

      if (getters.giftSelection.delivery !== "fetch") {
        price -= DELIVERY_COST;
      }

      price -= MINIMUM_GIFT_PRICE;

      if (price <= getters.giftSelection.giftcardValue) {
        commit("setSelectedGiftcardValue", null);
        `z`;
      }
    },

    determineAge({ getters, commit }) {
      let ages = [];
      if (getters.giftSelection.occasion === "birthday") {
        ages = [...Array(121).keys()];
      } else if (getters.giftSelection.occasion === "child") {
        ages = [...Array(51).keys()].filter((age) => age > 9 && age % 10 === 0);
      } else if (getters.giftSelection.occasion === "retirement") {
        ages = [...Array(121).keys()].filter(
          (age) => age > 44 && age % 10 === 0
        );
      } else if (getters.giftSelection.occasion === "wedding") {
        ages = [...Array(121).keys()].filter(
          (age) => age > 9 && age % 10 === 0
        );
      }

      if (!ages.length) {
        ages = [...Array(121).keys()].filter(
          (age) => age % 10 === 0 && age > 1
        );
      }

      ages.push("else");
      ages.push("na");
      commit("setAgeOptions", [...ages]);
    },

    filterProducts({ getters, commit }) {
      const selected = getters.giftSelection;
      const products = getters.products;
      let frequency = {};

      for (const product of products) {
        frequency[product.url] = 0;
      }

      for (const [key, value] of Object.entries(selected)) {
        if (value && value !== "n.v.t." && value !== "anders") {
          let filtered = products.filter(
            (item) => item.props[key] && item.props[key].includes(value)
          );

          for (const item of filtered) {
            frequency[item.url] += 1;
          }
        }
      }

      let orderedProd = products.sort(function (a, b) {
        return frequency[a.url] < frequency[b.url] ? 1 : -1;
      });

      commit("setFilteredProducts", orderedProd);
    },

    selectProduct({ getters, commit }, { url, selected }) {
      let selectedProducts = [...getters.selectedProducts];

      if (selected && !getters.isProductSelected(url)) {
        selectedProducts.push(url);
      } else {
        const index = selectedProducts.indexOf(url);
        if (index > -1) {
          selectedProducts.splice(index, 1);
        }
      }
      commit("setSelectedProducts", selectedProducts);
    },
  },
  mutations: {
    setPersonOptions(state, payload) {
      state.giftFinder.options.person = payload;
    },
    setAgeOptions(state, payload) {
      state.giftFinder.options.age = payload;
    },
    setDeliveryOptions(state, payload) {
      state.giftFinder.options.delivery = payload;
    },
    setGiftcardValuesOptions(state, payload) {
      state.giftFinder.options.giftcardValues = payload;
    },
    setFilteredProducts(state, payload) {
      state.filteredProducts = payload;
    },
    setSelectedGender(state, payload) {
      state.giftFinder.selected.gender = payload;
    },
    setSelectedPerson(state, payload) {
      state.giftFinder.selected.person = payload;
    },
    setSelectedAge(state, payload) {
      state.giftFinder.selected.age = payload;
    },
    setSelectedOccasion(state, payload) {
      state.giftFinder.selected.occasion = payload;
    },
    setSelectedDelivery(state, payload) {
      state.giftFinder.selected.delivery = payload;
    },
    setSelectedDate(state, payload) {
      state.giftFinder.selected.date = payload;
    },
    setSelectedPrice(state, payload) {
      state.giftFinder.selected.price = payload;
    },
    setSelectedGiftcard(state, payload) {
      state.giftFinder.selected.giftcard = payload;
    },
    setSelectedGiftcardValue(state, payload) {
      state.giftFinder.selected.giftcardValue = payload;
    },
    setSelectedProducts(state, payload) {
      state.selectedProducts = payload;
    },
  },
  modules: {
    email: email,
  },
});
